import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { isEmpty, isNil } from 'lodash'
import { useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { getSearchItemsQuery } from '../../../apiItemGraphql/userSearch'
import {
  getUserColumns,
  readJsonFromLocalStorage,
  writeFiltersToLocalStorage,
} from '../../../globalUtils/searchUtills'
// import { searchColumns } from '../searchConstants'
import HeaderCheckbox from './HeaderCheckbox'
import {
  setSelectedGridItems,
  setHeaderCheckboxState,
} from '../../../store/searchColumns'

import {
  MaintainLinkCellRenderer,
  thumbnailCellRenderer,
  ChipTextRenderer,
  InfoTableCellRenderer,
  CommentCellRenderer,
  HyperLinkCellRenderer,
} from './GridHelper'
import { parseJwt } from '../../../globalUtils/commonUtills'
import { setFilters } from '../../../store/userSearchProfile'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { SEARCH_ACTIONS } from '../../../constants/search'
import { getSearchColumns } from '../../../apiItemGraphql/userProfile'
import { useSearchSession } from '../useSearchSession'

export const useSearchGrid = () => {
  const dispatch = useDispatch()
  const makeToast = useToaster()
  const gridApi = useRef()
  const { updateSearchDatabase } = useSearchSession()
  let startRow = 0
  const [loading, setLoading] = useState(false)
  const [maximize, setMaximize] = useState(false)
  const [getCurrentPage, setGetCurrentPage] = useState(1)
  const [searchItems, setSearchItems] = useState([])
  const [getTotalPage, setGetTotalPage] = useState(0)
  const [paginationPageSizeValue, setPaginationPageSizeValue] = useState(100)
  const [getTotalResult, setGetTotalResult] = useState(0)
  const [selectedItems, setSelectedItems] = useState([])
  const [isExpanded, setIsExpanded] = useState(true)
  const location = useLocation()
  const [sortModel, setSortModel] = useState([])
  const { filters, savedSearch } = useSelector(
    (state) => state.userSearchProfile,
  )
  const { userType, roles, uId } = useSelector((state) => {
    const { userProfile } = state
    const token = parseJwt(userProfile.token)
    return {
      userType: token?.user_type,
      roles: token?.roles,
      uId: token?.lan_id,
    }
  }, [])
  const activeFilterTab = useSelector((state) => state.searchColumns.activeTab)
  const modifySearchColumns = getUserColumns(userType, roles)
  const {
    savedSearch: { activeSavedFilters },
  } = useSelector((state) => state.userSearchProfile)

  const { data: myColumns } = useQuery(getSearchColumns(), {
    variables: {
      filters: activeSavedFilters,
      useColumnPreference: true,
    },
  })

  const searchColumns = getUserColumns(userType, roles)

  const [searchParams] = useSearchParams()
  useEffect(() => {
    const handleBackNavigation = () => {
      const value = readJsonFromLocalStorage('LP_SEARCH_CRITERIA')
      const curUser = uId ? btoa(uId) : ''
      const preUser = value?.uId ? value?.uId : ''
      if (curUser && curUser !== preUser) {
        localStorage.removeItem('LP_SEARCH_CRITERIA') //remove localStorage when it's a different user
      } else if (curUser === preUser) {
        if (value) {
          dispatch(setFilters(value))
        } else {
          localStorage.removeItem('LP_SEARCH_CRITERIA')
        }
      }
    }
    handleBackNavigation()
  }, [location, dispatch, uId]) // Depend on location to trigger effect on navigation change
  const getFilterVariables = useCallback(() => {
    const tcins = searchParams.get('tcin')
    const tcinArray = tcins ? tcins.split(',') : []
    const searchText = tcinArray.length
      ? tcinArray.join(', ')
      : filters.searchText
    const allFilters = [
      ...filters.stickerTypeId,
      ...savedSearch?.activeSavedFilters,
    ]
    const params = {
      filters: allFilters,
      searchText: searchText,
      pageSize: 100,
      skip: startRow,
      sorting: sortModel,
    }
    if (!!searchText || Object.keys(allFilters).length > 0) {
      writeFiltersToLocalStorage('LP_SEARCH_CRITERIA', {
        ...params,
        savedSearch: {
          activeSavedFilters: savedSearch?.activeSavedFilters,
          activeSavedSearchId: savedSearch?.activeSavedSearchId,
        },
        uId: uId,
      })
    }
    return params
  }, [
    filters,
    searchParams,
    sortModel,
    startRow,
    savedSearch?.activeSavedFilters,
    savedSearch?.activeSavedSearchId,
    uId,
  ])

  const { refetch } = useQuery(getSearchItemsQuery(searchColumns), {
    variables: getFilterVariables(),
    skip: userType === undefined && isEmpty(getFilterVariables()),
    enabled: false, // you will need to enable this for the query to run
  })

  const refetchGrid = useCallback(async () => {
    setLoading(true)
    const newData = await refetch({
      variables: getFilterVariables(), // Ensure variables include sorting and other params
    })
    setLoading(false)
    if (newData.data?.searchItems?.results) {
      const { results } = newData.data.searchItems
      setSearchItems(results)
    }
  }, [refetch, getFilterVariables])

  const componentTypeMapper = (column) => {
    switch (column.componentType) {
      case 'maintainLink':
        return MaintainLinkCellRenderer
      case 'thumbnail':
        return thumbnailCellRenderer
      case 'chipText':
        return ChipTextRenderer
      case 'infoTable':
        return InfoTableCellRenderer
      case 'textInput':
        return CommentCellRenderer
      case 'hyperLink':
        return HyperLinkCellRenderer
      default:
        return
    }
  }

  const cellValueFormatterMapper = (column) => {
    switch (column.componentType) {
      case 'boolean':
        return booleanValueFormatter
      default:
        return
    }
  }
  const booleanValueFormatter = (params) => {
    if (isNil(params.value)) {
      return ''
    }

    return params.value === false ? 'No' : 'Yes'
  }

  const [deselectedRowKeys] = useState(new Set()) // Track manually deselected row TCINs when "Select All" is active

  const onSelectionChanged = async (e) => {
    // Get the selected nodes from the grid API
    const selectedNodes = e.api?.getSelectedNodes()
    // Check if 'select all' state is active
    const selectAllState = e.api.getServerSideSelectionState().selectAll
    const mapNodeToData = (node) => ({
      tcin: node?.data?.tcin,
      isMaintainable: node?.data?.isMaintainable,
      isDeletable:
        node?.data?.isMaintainable &&
        node?.data?.calculatedSetupStatus === 'Initiated',
      isMMBItem: node?.data?.isMMBItem,
      department: node?.data?.departmentId,
    })

    if (selectAllState) {
      // If 'select all' state is active, fetch all data from the grid
      // Select all available rows except manually deselected ones
      const rowData = []
      e.api.forEachNode((node) => {
        if (!deselectedRowKeys.has(node.data.tcin)) {
          rowData.push(mapNodeToData(node))
        }
      })
      // Set the selected items to the fetched data
      setSelectedItems(rowData)
      dispatch(setSelectedGridItems(rowData))
    } else {
      // If 'select all' state is not active, map only selected nodes
      const itemData = selectedNodes.map(mapNodeToData)
      // Set the selected items to the mapped data
      setSelectedItems(itemData)
      dispatch(setSelectedGridItems(itemData))
    }
  }

  const onRowSelected = (e) => {
    const tcin = e.node.data.tcin
    if (!e.node.isSelected()) {
      // Row is deselected,add to deselectedRowKeys
      deselectedRowKeys.add(tcin)
    } else {
      // Row is selected,remove from deselectedRowKeys
      deselectedRowKeys.delete(tcin)
    }
  }

  // Map the `visible` attribute and maintain API order if `activeFilterTab` is `my_columns`
  const finalColumns = useMemo(() => {
    if (activeFilterTab === 'my_columns' && myColumns?.searchColumns?.results) {
      return myColumns.searchColumns.results
        .map((apiColumn) => {
          const matchingColumn = modifySearchColumns.find(
            (column) => column.id === apiColumn.id,
          )

          if (matchingColumn) {
            return {
              ...matchingColumn,
              visible: apiColumn.visible,
              width: apiColumn.width,
            }
          }

          return null
        })
        .filter((column) => column !== null)
    } else {
      return modifySearchColumns
    }
  }, [activeFilterTab, myColumns, modifySearchColumns])

  const buildColumns = (columns) => {
    const agGridColumns = columns
      // Added in a filter for a new attribute notUserVisible. If set to true, the column will not render.
      // It does not appear ag-grid can specifically hide a column from everything, it's either invisible (but still visible in the custom panel) or visible to everything.
      .filter((column) => !column.notUserVisible)
      .map((column) => ({
        colId: column.id,
        field: column.id,
        headerName: column.displayName,
        sortable: column.sortable,
        cellRenderer: componentTypeMapper(column),
        // cellStyle: cellStyleMapper(column),
        valueFormatter: cellValueFormatterMapper(column),
        minWidth: 75, // don't let them resize the column out of existence
        width: column.width,
        hide: !column.visible,
      }))
    // Add selection column to front
    agGridColumns.unshift({
      colId: 'selection',
      field: 'selection',
      headerName: '',
      checkboxSelection: true,
      maxWidth: 75,
      pinned: 'left',
      headerTooltip: 'Select All | Max 10,000',
      headerComponent: HeaderCheckbox,
      // cellClass: agGridCheckBoxStyles,
    })

    return agGridColumns
    //
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gridColumns = useMemo(() => buildColumns(finalColumns), [finalColumns])

  const gridOptions = useMemo(
    () => ({
      ensureDomOrder: true,
      rowModelType: 'serverSide',
      pagination: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      alwaysShowHorizontalScroll: true,
      alwaysShowVerticalScroll: true,
      enableCellTextSelection: true,
      tooltipShowDelay: 0,
    }),
    [],
  )
  const datasource = () => {
    return {
      getRows: async (params) => {
        setGetTotalResult(0)
        try {
          setLoading(true)
          const newsortModel = params.request.sortModel || []
          const newSorting = newsortModel.map((sortItem) => ({
            id: sortItem.colId,
            direction: sortItem.sort,
          }))
          setSortModel(newSorting)
          const newData = await refetch({
            skip: params?.request?.startRow,
          })
          setLoading(false)
          if (newData.data?.searchItems?.results) {
            const { results, total } = newData.data.searchItems

            setSearchItems(results)
            setGetTotalResult(total)
            return params.success({
              rowData: results,
              rowCount: total,
            })
          } else {
            setLoading(false)
            return params.fail()
          }
        } catch {
          setLoading(false)
          return params.fail()
        }
      },
    }
  }
  const onGridReady = (gridReadyParams) => {
    gridReadyParams.api.setGridOption('serverSideDatasource', datasource())
    gridReadyParams.api.addEventListener('newColumnsLoaded', () => {
      gridReadyParams.api.resetColumnState()
    })
    // Now that the grid is ready, set some listeners to handle our scrolling behavior.
    // The goal is that the we should not allow scrolling within the grid until the grid fully fills the screen.
    // Inspired by: // https://stackoverflow.com/questions/56302826/how-do-i-add-an-event-listener-on-position-of-a-div
    const tableBody = document.querySelector(
      '.ag-body-viewport.ag-layout-normal',
    )
    tableBody.style.overflow = 'hidden' // Set default
  }

  useEffect(() => {
    if (gridApi.current) {
      const gridCurr = gridApi.current
      gridCurr.api?.setGridOption('serverSideDatasource', datasource())
      dispatch(setHeaderCheckboxState(false))
      gridApi.current?.api?.deselectAll() // Reset selected when grid is refreshed
      updateSearchDatabase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, savedSearch?.activeSavedFilters])
  const handlePaginationPageSizeChange = (id, value) => {
    setPaginationPageSizeValue(value)
  }

  const onBtFirst = useCallback(() => {
    gridApi.current.api.paginationGoToFirstPage()
  }, [])

  const onBtLast = useCallback(() => {
    const paginationGetRowCount = gridApi.current.api.paginationGetRowCount()
    if (paginationGetRowCount < SEARCH_ACTIONS.MAX_SELECTABLE) {
      gridApi.current.api.paginationGoToLastPage()
    } else {
      makeToast({
        type: 'alert',
        heading: 'Limit exceeded!',
        message: `Please adjust the filters to fit within the ${SEARCH_ACTIONS.MAX_SELECTABLE.toLocaleString()} limit.`,
      })
    }
  }, [makeToast])

  const onBtNext = useCallback(() => {
    gridApi.current.api.paginationGoToNextPage()
  }, [])

  const onBtPrevious = useCallback(() => {
    gridApi.current.api.paginationGoToPreviousPage()
  }, [])

  const goToPage = (pageNumber) => {
    if (gridApi.current && gridApi.current.api) {
      const zeroBasedPageNumber = pageNumber - 1

      gridApi.current.api.paginationGoToPage(zeroBasedPageNumber)
    }
  }

  const onBtSpecificPage = useCallback((event) => {
    goToPage(Number(event.target.value))
  }, [])

  const onPaginationChanged = useCallback(() => {
    if (gridApi.current.api) {
      setGetCurrentPage(gridApi.current.api.paginationGetCurrentPage() + 1)
      // const countRows = gridApi.current.api.paginationGetRowCount()
      // console.log(gridApi.current.api)
      // if (countRows > 0 && getTotalResult !== countRows) {
      //   setGetTotalResult(countRows)
      // } // Set this total from API
      setGetTotalPage(gridApi.current.api.paginationGetTotalPages())
      dispatch(setHeaderCheckboxState(false)) // Todo temp fix for MTA download
      gridApi.current?.api?.deselectAll() // Todo temp fix for MTA download
    }
  }, [gridApi, dispatch])

  const openCloseToolPanel = useCallback((key, flag) => {
    if (flag) {
      gridApi.current.api.openToolPanel(key)
    } else {
      gridApi.current.api.closeToolPanel()
    }
    gridApi.current.api.setSideBarVisible(flag)
  }, [])

  const getRowId = useCallback((params) => {
    return params.data.tcin
  }, [])

  return {
    loading,
    searchItems,
    onGridReady,
    searchColumns,
    gridColumns,
    onBtFirst,
    onBtLast,
    onBtNext,
    onBtPrevious,
    onBtSpecificPage,
    gridApi,
    onPaginationChanged,
    getCurrentPage,
    getTotalPage,
    paginationPageSizeValue,
    getTotalResult,
    gridOptions,
    openCloseToolPanel,
    maximize,
    setMaximize,
    selectedItems,
    handlePaginationPageSizeChange,
    onSelectionChanged,
    onRowSelected,
    getRowId,
    refetchGrid,
    isExpanded,
    setIsExpanded,
  }
}
